import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route
        path={`${match.url}settings`}
        component={asyncComponent(() => import("./Settings"))}
      />
      <Route
        path={`${match.url}Paths`}
        component={asyncComponent(() => import("./Paths"))}
      />
        <Route
            path={`${match.url}proximity`}
            component={asyncComponent(() => import("./Proximity"))}
        />
      <Route
        path={`${match.url}Tracking`}
        component={asyncComponent(() => import("./Tracking"))}
      />
      <Redirect to={`/Tracking`} />
    </Switch>
  </div>
);

export default App;
