import { useEffect, useState } from "react";
import { straconClient } from "../../../util/Api";
import { useCookies } from "react-cookie";

export const useProvideAuth = () => {
  const [authUser, setAuthUser] = useState(null);
  const [error, setError] = useState("");
  const [isLoadingUser, setLoadingUser] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const [cookies, setCookie] = useCookies(["trackingsessionid"]);

  const fetchStart = () => {
    setLoading(true);
    setError("");
  };

  const fetchSuccess = () => {
    setLoading(false);
    setError("");
  };

  const fetchError = (error) => {
    setLoading(false);
    setError(error);
  };

  const userLogin = (values, callbackFun) => {
    straconClient.defaults.headers.common["Authorization"] =
      process.env.REACT_APP_TOKEN_API_STRACON;

    if (cookies.trackingsessionid) {
      fetchStart();
      straconClient
        .get("get_usuario/", {
          params: {
            trackingsessionid: cookies.trackingsessionid,
          },
        })
        .then(({ data }) => {
          localStorage.setItem("tracking_enabled", data.tracking_enabled);
          localStorage.setItem("proximity_enabled", data.proximity_enabled);
          if (data.is_active) {
            //if (data.result) {
            fetchSuccess();
            //console.log(data);
            //localStorage.setItem('token', data.jwt);
            getAuthUser();
            if (callbackFun) callbackFun();
          } else {
            fetchError(data.error);
          }
        })
        .catch(function (error) {
          fetchError(error.message);
        });
    }
  };

  const userSignup = (user, callbackFun) => {
    fetchStart();
    straconClient
      .post("auth/register", user)
      .then(({ data }) => {
        if (data.result) {
          fetchSuccess();
          //localStorage.setItem("token", data.token.access_token);
          straconClient.defaults.headers.common["Authorization"] =
            process.env.REACT_APP_TOKEN_API_STRACON;
          getAuthUser();
          if (callbackFun) callbackFun();
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const sendPasswordResetEmail = (email, callbackFun) => {
    fetchStart();

    setTimeout(() => {
      fetchSuccess();
      if (callbackFun) callbackFun();
    }, 300);
  };

  const confirmPasswordReset = (code, password, callbackFun) => {
    fetchStart();

    setTimeout(() => {
      fetchSuccess();
      if (callbackFun) callbackFun();
    }, 300);
  };

  const renderSocialMediaLogin = () => null;

  const userSignOut = (callbackFun) => {
    straconClient.defaults.headers.common["Authorization"] = "";
    //localStorage.removeItem("token");
    cookies.trackingsessionid = "";
    setAuthUser(false);
    callbackFun();
  };

  /*   const userSignOut = (callbackFun) => {
      fetchStart();
      straconClient
        .post('auth/logout')
        .then(({ data }) => {
          if (data.result) {
            fetchSuccess();
            straconClient.defaults.headers.common['Grpc-Metadata-Authorization'] = '';
            localStorage.removeItem('token');
            setAuthUser(false);
            if (callbackFun) callbackFun();
          } else {
            fetchError(data.error);
          }
        })
        .catch(function (error) {
          fetchError(error.message);
        });
    }; */

  const getAuthUser = () => {
    if (cookies.trackingsessionid) {
      fetchStart();
      straconClient
        .get("get_usuario/", {
          params: {
            trackingsessionid: cookies.trackingsessionid,
          },
        })
        .then(({ data }) => {
          localStorage.setItem("tracking_enabled", data.tracking_enabled);
          localStorage.setItem("proximity_enabled", data.proximity_enabled);
          if (data.is_active) {
            //if (data.user) {
            fetchSuccess();
            setAuthUser(data);
          } else {
            fetchError(data.error);
          }
        })
        .catch(function (error) {
          //straconClient.defaults.headers.common['Grpc-Metadata-Authorization'] = '';
          fetchError(error.message);
        });
    }
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.

  useEffect(() => {
    straconClient.defaults.headers.common["Authorization"] =
      process.env.REACT_APP_TOKEN_API_STRACON;
    //const token = cookies.get('trackingsessionid')
    //console.log(cookies.trackingsessionid);
    straconClient
      //cambiamos el .post por un .get
      .get("get_usuario/", {
        params: {
          trackingsessionid: cookies.trackingsessionid,
        },
      })
      .then(({ data }) => {
        //console.log(data);
        localStorage.setItem("tracking_enabled", data.tracking_enabled);
        localStorage.setItem("proximity_enabled", data.proximity_enabled);
        if (cookies.trackingsessionid) {
          if (data.is_active) {
            //if (data.user) {
            setAuthUser(data);
          }
        }
        setLoadingUser(false);
      })
      .catch(function (error) {
        //localStorage.removeItem("token");
        straconClient.defaults.headers.common["Authorization"] = "";
        setLoadingUser(false);
      });
  }, []);

  // Return the user object and auth methods
  return {
    isLoadingUser,
    isLoading,
    authUser,
    error,
    setError,
    setAuthUser,
    getAuthUser,
    userLogin,
    userSignup,
    userSignOut,
    renderSocialMediaLogin,
    sendPasswordResetEmail,
    confirmPasswordReset,
  };
};
