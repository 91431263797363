import axios from "axios";

const baseUrl = "https://iotstracontech.stracontech.com"
//const baseUrl = "http://localhost:8000"

const straconClient = axios.create({
  baseURL: `${baseUrl}/api/`, //YOUR_API_URL HERE
  headers: {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_TOKEN_API_STRACON,
  },
});

const proximityClient = axios.create({
    baseURL: `${baseUrl}/api_proximity/`,
    headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_TOKEN_API_PROXIMITY,
    },
});

export {
    straconClient,
    proximityClient
}

//"Accept, Content-Type, Content-Length, Accept-Encoding, Grpc-Metadata-Authorization"

/*
'Content-Length':'',
    'Accept-Encoding':'',
    'Grpc-Metadata-Authorization':'',
*/
